/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

html {
    font-size: 10px;
    width: 100%;
    height:100%;
}

body {
    color: #5a5a5a;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../public/images/background.png");
}

/* NAV  BAR
-------------------------------------------------- */

.navbar {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.center {
    display: flex;
    justify-content: center;
    width: 35%;
}

.left-side {
    display: flex;
    justify-content: start;
    width: 35%;
}

.right-side {
    display: flex;
    justify-content: end;
    width: 35%;
    margin-right: 3rem;
}

.menu-img-ryu {
    width: 19rem;
    height: 3.5rem;
    margin-left: 3rem;
    margin-right: 10rem;
    background: url('../../public/images/RYU_GAMES.png') no-repeat;
    background-size: 100%;
    cursor: pointer;
}

.menu-img-ryu:hover {
    width: 19rem;
    height: 3.5rem;
    margin-left: 3rem;
    margin-right: 10rem;
    background: url('../../public/images/RYU_GAMES_hover.png') no-repeat;
    background-size: 100%;
    cursor: pointer;
}


.menu-img {
    width: 22rem;
    height: 3.5rem;
    margin-left: 3rem;
    margin-right: 8rem;
    background: url('../../public/images/WHITE_PAPER.png') no-repeat;
    background-size: 100%;
    cursor: pointer;
}

.menu-img:hover {
    width: 22rem;
    height: 3.5rem;
    margin-left: 3rem;
    margin-right: 8rem;
    background:url('../../public/images/WHITE_PAPER_hover.png') no-repeat;
    background-size: 100%;
    cursor: pointer;
}

.crystal-quest-logo {
    height: 130px;
    width: 176px;
    margin-top: 3rem;
    cursor: pointer;
}


/* HOME CONTENT
-------------------------------------------------- */

.App-intro {
    display: flex;
    width: 100%;
}

.home-image-container {
    position: relative;
    width: 98%;
    max-width: 1200px;
    margin: 0 auto;
}

.home-image {
    width: 100%;
    object-fit: cover;
}

.discord-icon{
    width: 9rem;
    bottom: 8%;
    cursor: pointer;
    margin-left: 8rem;
}

.telegram-icon{
    width: 9rem;
    bottom: 8%;
    cursor: pointer;
    margin-right: 4rem;
    margin-left: 4rem;
}

.twitter-icon{
    width: 9rem;
    bottom: 8%;
    cursor: pointer;
}


/* Popup Menu
-------------------------------------------------- */
.popup-container {
    position: absolute;
    width: 131px;
    height: 90px;
    right: 50px;
    top: 100px;
    z-index: 100;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    background: #7924CA;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.19), inset 0 -2px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.popup-menu {
    width: 100%;
    height: 80%;
}


/* RESPONSIVE CSS
-------------------------------------------------- */
@media (max-width: 1400px) {
    body {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }


    .link{
        margin-right: 2rem;
    }

    .menu-img-ryu {
        width: 15rem;
        margin-left: 3rem;
        margin-right: 1rem;
    }
    .menu-img {
        width: 18rem;
        margin-left: 3rem;
        margin-right: 1rem;
    }

    .discord-icon{
        width: 9rem;
        bottom: 8%;
        cursor: pointer;
        margin-left: 3rem;
    }

    .telegram-icon{
        width: 9rem;
        bottom: 8%;
        cursor: pointer;
        margin-right: 3rem;
        margin-left: 3rem;
    }

    .twitter-icon{
        width: 9rem;
        bottom: 8%;
        cursor: pointer;
    }

}

@media (max-width: 1110px) {
    body {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    .center {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .crystal-quest-logo {
        width: 169px;
        height: 128px;
    }

    .left-side {
        position: absolute;
        width: 100%;
        bottom: 5%;
        display: flex;
    }

    .icons-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .right-side {
        position: absolute;
        top:10%;
        width: 100%;
    }

    .menu-img {
        position: absolute;
        width: 25rem;
        height: 5rem;
        left: 0;
        margin-left: 3rem;
        margin-right: 0;
    }

    .menu-img:hover {
        position: absolute;
        width: 25rem;
        height: 5rem;
        left: 0;
        margin-left: 3rem;
        margin-right: 0;
    }

    .menu-img-ryu {
        position: absolute;
        width: 23rem;
        height: 5rem;
        right: 0;
        margin-left: 0;
        margin-right: 3rem;
    }

    .menu-img-ryu:hover {
        position: absolute;
        width: 23rem;
        height: 5rem;
        right: 0;
        margin-left: 0;
        margin-right: 3rem;
    }

    .discord-icon{
        width: 10rem;
        bottom: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .telegram-icon{
        width: 10rem;
        bottom: 0;
        margin-right:3rem;
        margin-left: 3rem;
    }

    .twitter-icon{
        width: 10rem;
        bottom: 0;
        margin-right: 0;
    }

    .popup-container {
        width: 171px;
        height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .popup-inner-container {
        justify-content: center;
        display: flex;
        width: 100%;
        margin: 10% 0 10% 0;
    }
}

@media (max-width: 600px) {
    .navbar {
        padding: 0;
    }

    body {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    .center {
        width: 100%;
        justify-content: center;
        display: flex;
    }

    .crystal-quest-logo {
        width: 169px;
        height: 128px;
    }

    .left-side {
        position: absolute;
        width: 100%;
        bottom: 5%;
        display: flex;
    }

    .icons-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .right-side {
        position: absolute;
        top:10%;
        width: 100%;
    }

    .menu-img {
        position: absolute;
        width: 15rem;
        left: 0;
        margin-left: 1rem;
        margin-right: 0;
    }

    .menu-img-ryu {
        position: absolute;
        width: 13rem;
        right: 0;
        margin-left: 0;
        margin-right: 1rem;
    }

    .menu-img:hover {
        position: absolute;
        width: 15rem;
        left: 0;
        margin-left: 1rem;
        margin-right: 0;
    }

    .menu-img-ryu:hover {
        position: absolute;
        width: 13rem;
        right: 0;
        margin-left: 0;
        margin-right: 1rem;
    }

    .discord-icon{
        width: 8rem;
        bottom: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .telegram-icon{
        width: 8rem;
        bottom: 0;
        margin-right:3rem;
        margin-left: 3rem;
    }

    .twitter-icon{
        width: 8rem;
        bottom: 0;
        margin-right: 0;
    }

    .popup-container {
        width: 171px;
        height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .popup-inner-container {
        justify-content: center;
        display: flex;
        width: 100%;
        margin: 10% 0 10% 0;
    }
}

@media (max-width: 300px) {
    .menu-img {
        position: absolute;
        width: 12rem;
        left: 0;
        margin-left: 1rem;
        margin-right: 0;
    }

    .menu-img-ryu {
        position: absolute;
        width: 10rem;
        right: 0;
        margin-left: 0;
        margin-right: 1rem;
    }

    .menu-img:hover {
        position: absolute;
        width: 12rem;
        left: 0;
        margin-left: 1rem;
        margin-right: 0;
    }

    .menu-img-ryu:hover {
        position: absolute;
        width: 10rem;
        right: 0;
        margin-left: 0;
        margin-right: 1rem;
    }

    .discord-icon{
        width: 5rem;
        bottom: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .telegram-icon{
        width: 5rem;
        bottom: 0;
        margin-right:3rem;
        margin-left: 3rem;
    }

    .twitter-icon{
        width: 5rem;
        bottom: 0;
        margin-right: 0;
    }
}
