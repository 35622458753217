/* Global */

* {
    margin: 0px;
    font-family: azo-sans-web, sans-serif;
    color: #1f2638;
}

#root {
    overflow: hidden;
}

.navigation-bar {
    height: 115px;
}

.investBar {
    height: 115px;
}

.navbar-brand {
    margin-top: 8px;
    padding-left: 55px;
}

.navbar-toggler {
    border: none;
}

/* Facebook */
.facebook-ryu {
    background-color: white !important;
    border: none !important;
}

/* Submit Game */
.submit-form-group input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #b7b7b7;
    font-weight: 300;
}
.submit-form-group input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #b7b7b7;
    font-weight: 300;
    opacity: 1;
}
.submit-form-group input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #b7b7b7;
    font-weight: 300;
    opacity: 1;
}
.submit-form-group input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b7b7b7;
    font-weight: 300;
}
.submit-form-group input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b7b7b7;
    font-weight: 300;
}

.submit-form-group input::placeholder {
    /* Most modern browsers support this now. */
    color: #b7b7b7;
    font-weight: 300;
}

.submit-game-button {
    background-color: #2c9ffc;
    height: 70px;
    box-shadow: 0px 0px 20px rgba(44, 159, 252, 0.5);
    border: none;
    margin-top: 50px;
    margin-bottom: 100px;
}

.submit-game-heading {
    font-weight: bold;
    font-size: 70px;
}

/* Menu */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9999999;
    top: 0;
    right: 0;
    background-color: #1f2638;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #999999;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    background-color: #1f2638;
    color: #999999;
    border: none;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

/* Position and sizing of burger button */
.bm-burger-button {
    display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    right: 265px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
    background: #1f2638;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: white;
    padding-bottom: 20px;
    font-weight: 600;
}

.bm-item:hover {
    color: red;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/* Top Section */

.ryu-logo {
    width: 140px;
    margin-top: 30px;
    margin-left: 40px;
    margin-bottom: -50px;
}

.top-text-ctn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 10%;
}

.top-text-ctn h1 {
    font-weight: 900;
    font-size: 80px;
    color: white;
}

.top-text-ctn h3 {
    font-weight: 300;
    font-size: 40px;
    color: #85ebff;
    padding: 20px 0;
}

.arrow-right-input-icon {
    position: absolute;
    right: 20px;
    bottom: 88px;
    width: 43px;
}

.submit-text {
    position: absolute;
    text-align: right;
    right: 75px;
    bottom: 73px;
    width: 200px;
    font-size: 30px;
    font-weight: 700;
    color: #258bfa;
    transition: all 0.1s linear;
}

.top-submit-game-ctn {
    text-align: center;
    font-size: 25px;
    margin-top: 30px;
}

.top-submit-game-ctn span {
    color: #258bfa;
    font-weight: 300;
}

.top-submit-game-ctn a {
    color: #85ebff;
    font-weight: 400;
}

/* style={{}} */

#arrow-circle {
    transition: all 0.1s linear;
}

.submit-hover-area {
    position: absolute;
    right: 0;
    width: 230px;
    height: 92px;
    z-index: 100;
}

.top-text-ctn input {
    font-size: 30px;
    height: 92px;
    border: none;
    box-shadow: 0px 0px 30px #c4c4c4;
    padding-left: 30px;
}

.top-text-ctn input::placeholder {
    color: #b6b6b6;
}

.ryu-phone {
    height: 650px;
}

@media only screen and (max-width: 991px) {
    .top-text-ctn {
        position: relative;
        top: inherit;
        transform: inherit;
        padding-left: inherit;
        padding-right: inherit;
        margin: inherit;
    }

    .arrow-right-input-icon {
        position: absolute;
        right: 7%;
        bottom: 57px;
        width: 43px;
    }

    .submit-text {
        right: calc(7% + 45px);
        bottom: 48px;
        width: 115px;
        font-size: 20px;
        font-weight: 700;
        color: #2c9ffc;
    }

    .top-submit-game-ctn {
        font-size: 20px;
        margin-top: 20px;
    }

    .ryu-logo {
        width: 60px;
        margin-top: 15px;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .top-text-ctn h1 {
        font-size: 40px;
        text-align: center;
    }

    .top-text-ctn h3 {
        font-size: 30px;
        padding-right: 20px;
        text-align: center;
    }

    .top-text-ctn input {
        height: 60px;
        max-width: 90%;
        margin: auto;
        font-size: 20px;
    }

    .ryu-phone {
        margin: auto;
        display: block;
        height: 400px;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 577px) {
    .top-text-ctn input {
        max-width: 100%;
    }
}

/* Middle Section */
.sub-row {
    padding: 70px;
}

.card-icon-ctn {
    height: 80px;
    width: 80px;
    margin: 10px auto;
    border-radius: 40px;
}

.value-add-card {
    border-radius: 20px;
    background-color: white;
    padding-top: 10px;
    box-shadow: 0px 0px 30px #e4e3e3 !important;
}

.value-add-card-text {
    color: #979797;
    font-weight: 300;
}

.value-add-card-title {
    color: #06067a;
    font-weight: 900;
    font-size: 30px;
}

.welcome-sub-title {
    font-size: 60px;
    color: white;
    font-weight: 700;
}

@media only screen and (max-width: 991px) {
    .welcome-sub-title {
        font-size: 40px;
    }

    .sub-row {
        padding: 70px 10px;
    }
}

/* Featured Section */

.featured-game-card {
    border-radius: 20px;
    max-width: 400px;
}

.featured-game-card-left {
    float: left;
}

.featured-game-card-right {
    float: right;
}

@media only screen and (max-width: 991px) {
    .featured-game-card-right,
    .featured-game-card-left {
        float: none;
        margin: auto;
    }
}

/* OLD Top Section */

.faq-li {
    width: 100px;
    cursor: pointer;
    border-left: 1px solid #dddddd;
}

.faq-button-light {
    position: absolute;
    right: 30px;
    top: 50px;
    width: 100px;
}

.faq-button-light-text {
    text-align: center !important;
    padding-top: 15px;
    color: #555555;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    width: 100%;
}

.top-section-logo-container {
    position: relative;
    top: -35px;
    margin-top: -35px;
}

.top-section-logo {
    position: absolute;
    left: 50px;
    top: 110px;
    z-index: 999;
}

.top-section-product-list-container {
    position: relative;
    top: -35px;
    margin-top: -35px;
}

.top-section-product-list {
    position: absolute;
    left: 0;
    top: 140px;
    z-index: 999;
    list-style-type: none;
    overflow: hidden;
}

.top-section-product-list li {
    float: left;
}

.top-section-product-list li p {
    font-weight: 300;
}

.phone-video-container {
    background-image: linear-gradient(180deg, #ebf1e9 0%, #f6f9fc 98%);
    height: calc(100vh + 35px);
}

.top-section-cta-container {
    height: calc(100vh + 35px);
}

.cta-container {
    position: relative;
    top: 55%;
    transform: translateY(-45%);
    margin-left: 50px;
}

.cta-container h1,
h2 {
    color: #555555;
}

.cta-container h1 {
    font-weight: bold;
    font-size: 60px;
}

.cta-container h2 {
    font-weight: 300;
    font-size: 50px;
    margin-bottom: 30px;
}

.cta-container p {
    max-width: 500px;
    font-weight: 300;
    font-size: 20px;
    color: #777777;
    margin-bottom: 50px;
    margin-right: 20px;
}

.bold-span-sub {
    font-weight: bold;
    color: #555555;
}

.video-label-container {
    /* background-color: blue; */
    height: 70px;
    width: 400px;
    position: relative;
    top: 35%;
    transform: translateY(-65%);
    margin-left: auto;
    margin-right: auto;
}

.social-video-label-container,
.tournament-video-label-container {
    /* background-color: orange; */
    height: 100%;
    width: 175px; /* Same as phone  */
    text-align: center;
    padding-top: 5px;
}

.social-video-label-container {
    float: left;
}

.tournament-video-label-container {
    float: right;
}

.top-white-bar {
    width: 440px;
    height: 2px;
    background: white;
    z-index: 1;
    position: absolute;
    left: -10px;
}

.bottom-white-bar {
    width: 340px;
    height: 2px;
    background: white;
    z-index: 1;
    position: absolute;
    left: -10px;
    bottom: 0;
}

.animated-gif {
    height: auto;
    width: 175px;
    border-radius: 30px;
    -webkit-transition: -webkit-filter 200ms linear;
    transition: filter 200ms linear;
}

.phone-video {
    height: 400px;
    width: 250px;
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
}

.animated-gif-welcome {
    height: 498px;
    width: 250px;
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;
}

.animated-gif:hover {
    filter: grayscale(0%) !important;
}

.still-screen {
    height: 450px;
    margin-left: 150px;
}

.app-caption-text {
    display: inline-block;
    padding-right: 240px;
    margin-left: -40px;
    color: #666666;
    margin-top: 10px;
    font-weight: 300;
}

.iMessage-extension-text {
    display: inline-block;
    margin-left: -50px;
    color: #666666;
    font-weight: 300;
}

.phones-main-caption-text {
    margin-left: auto;
    margin-right: auto;
    color: #666666;
    font-weight: 300;
    width: 500px;
    transform: translate(-50px, 0px);
}

.beta-container {
    position: absolute;
    height: 44px;
    width: 145px;
    background-color: #f5a623;
    left: 62%;
    top: 10px;
    z-index: 100;
    border-radius: 22px;
    box-shadow: 0px 2px 5px rgba(30, 35, 53, 0.5);
}

.right-img-container-main {
    margin: auto 0;
    text-align: center;
}

.main-button-primary {
    background-color: #6be8b0;
    border-radius: 2px;
    width: 200px;
    color: #1e2335;
    box-shadow: 0px 2px 5px rgba(30, 35, 53, 0.15);
    transition: all 0.15s ease;
}

.main-button-primary:hover {
    background-color: #6be8b0;
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    transform: translate(0, -3px);
}

.main-button-secondary {
    background-color: #e3f1ff;
    border-radius: 2px;
    width: 200px;
    color: #1e2335;
    box-shadow: 0px 2px 5px rgba(30, 35, 53, 0.15);
    transition: all 0.15s ease;
    margin-left: 30px;
}

.main-button-secondary:hover {
    background-color: #e3f1ff;
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    transform: translate(0, -3px);
}

.green-check {
    color: #6be8b0;
    font-family: cursive;
}

.unity-swift-checks {
    margin-top: 10px;
}

.unity-swift-checks p {
    color: #b4b4b4;
    font-size: 80%;
}

.button-arrow {
    float: right;
    margin-top: 3px;
}

.darkBlue {
    background-color: black;
}

.semi {
    font-weight: 600;
    font-size: 36px;
}

.thick {
    font-weight: 900;
    font-size: 54px;
}

.main-sub {
    margin-top: 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #666666;
}

.underline-green {
    border-bottom: 10px solid #6be8b0;
}

.underline-green-light {
    padding-bottom: 10px;
    border-bottom: 3px solid #6be8b0;
}

.title {
    font-weight: 900;
    font-size: 72px;
}

.thin {
    font-weight: 300;
    font-size: 16px;
    max-width: 201px;
}

.learnmore {
    text-decoration: underline;
    color: black !important;
    font-weight: 400;
    font-size: 20px;
}

.main-image {
    margin-top: 30px;
    height: 450px;
}

.modal-backdrop {
    background-color: #6be8b0;
}

.modal-title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 110%;
    font-weight: bold;
}

.main-text-and-buttons {
    margin-top: 100px;
    padding-right: 0px;
    min-width: 560px;
    max-width: 620px;
}

.btn-submit-form,
.btn-submit-form:disabled {
    background-color: #6be8b0;
    border-radius: 2px;
    width: 200px;
    color: #1e2335;
    box-shadow: 0px 2px 5px rgba(30, 35, 53, 0.15);
    transition: all 0.15s ease;
    margin-left: 30px;
    border: none;
}

.btn-submit-form:hover {
    background-color: #6be8b0 !important;
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    transform: translate(0, -3px);
    color: black;
    border: none !important;
}

.comingsoonButton {
    padding: 0;
    border: none;
    background: none;
    margin-right: -140%;
}
.comingsoonButtonPlay {
    padding: 0;
    border: none;
    background: none;
    margin-top: 0;
}
.comingsoonButtonFooter {
    padding: 0;
    border: none;
    background: none;
    margin-top: 0;
}

.call-to-action-row {
    margin-top: -50px;
}

.arrowBox {
    text-align: left;
    font-weight: 400;
    font-size: 20px;
}

.blueText {
    color: #0076ff;
}

.top-section-main-container {
    margin-bottom: 100px;
}

.iMessage-image {
    height: 420px;
    position: absolute;
    transform: translate(-208px, 15px);
}

/* Middle Section */

.midde-section-container {
    padding-top: 100px;
}

.downloads-container {
    padding-top: 180px;
    padding-bottom: 75px;
}

.catalog-container-1 {
    margin-top: 80px;
    width: 100%;
}
.catalog-container-2 {
    margin-top: 50px;
    width: 100%;
}

.focus-row {
    padding-top: 70px;
}

.offWhite {
    background-color: #f6f9fc;
}

.lightBlue {
    background-color: rgba(114, 200, 249, 0.17);
}

.centered {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.outerRound {
    height: 115px;
    width: 115px;
    border-radius: 50%;
}

.innerRound {
    margin-top: 7.5px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.gamesTitle {
    text-transform: uppercase;
    color: #72c8f9;
    font-weight: 900;
    font-size: 18px;
}

.focusContainer {
    width: 100%;
}

.focusImage {
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
}

.focusTitle {
    font-weight: 400;
    font-size: 22px;
}

.focusBody {
    font-weight: 300;
    font-size: 20px;
}

.shadow {
    -webkit-box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px;
    -moz-box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px;
}

.focusDiv {
    background-color: #41d2fc;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    margin-top: -55px;
    height: 180px;
    position: absolute;
    width: 511px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-collapse: collapse;
}

.gameTitle {
    font-weight: 700;
    font-size: 25px;
    font-style: italic;
    color: black;
    margin-left: 9%;
    margin-top: 15px;
}

.gameBody {
    margin-left: 9%;
    margin-right: 9%;
    color: white;
    font-size: 22px;
    font-weight: 300;
    font-style: italic;
}

.downloadsTitle {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 20px;
}

.rowImage {
    width: 50%;
    border-radius: 20% !important;
}

.descriptionText {
    margin-top: 5px;
    font-weight: 300;
    font-size: 14px;
}

/* Hello */

.hello-read-docs {
    background-color: #e3f1ff;
    border-radius: 2px;
    width: 200px;
    height: 45px;
    color: #1e2335;
    box-shadow: 0px 2px 5px rgba(30, 35, 53, 0.15);
    transition: all 0.15s ease;
    float: right;
    margin-top: 20px;
}

.hello-read-docs:hover {
    background-color: #e3f1ff;
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    transform: translate(0, -3px);
}

.hello-svg-back {
    position: absolute;
    width: 100%;
    left: 0px;
    z-index: -1002;
    overflow: hidden;
    top: 200px;
    height: 800px;
}

.handshake-items-container {
    padding-top: 20px;
    padding-left: 10%;
}

.handshake-items-container img {
    display: inline-flex;
    width: 60px;
    height: 60px;
    border-radius: 7px;
    align-items: center;
    object-fit: scale-down;
    float: left;
    box-shadow: 0px 2px 5px rgba(30, 35, 53, 0.15);
    transition: all 0.15s ease;
}

.handshake-items-container img:hover {
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    transform: translate(0, -3px);
}

.ryu-logo-hello {
    background-color: #1f2638;
}

.emoji-handshake {
    display: inline-flex;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 40px;
    min-height: 60px;
    align-items: center;
}

.hello-title {
    font-size: 30px;
    font-weight: bold;
    color: #1e2335;
}

.hello-sub-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    color: #1e2335;
}

.hello-asterix-title {
    color: #777777;
    font-size: 9px;
    font-weight: 300;
}

.hello-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 80px;
    margin-top: 30px;
}

.hello-title-col {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 400px;
    padding-left: 50px;
}

.hello-sketch-col {
    padding-left: 50px;
}

.hello-game-sketch {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.animated-gif-hello {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block;
    padding: 40px 0;
    border-radius: 50px;
}

.launch-boost-text-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
}

.hello-video-col {
    min-width: 300px;
}

.launch-text-ctn {
    padding-left: 100px;
    min-width: 340px;
}

.boost-text-ctn {
    padding-right: 100px;
    min-width: 360px;
}

.feature-list {
    margin-top: 100px;
    margin-bottom: 100px;
    list-style: none;
    box-shadow: 0px 10px 20px rgba(24, 48, 85, 0.3);
    padding: 0;
}

.feature-list li {
    float: left;
    width: 33.3%;
    background-color: white;
    height: 140px;
}

.feature-list li div {
    height: 130px;
    margin-top: 10px;
}

.center-feature-list {
    border-right: 1px solid rgba(24, 48, 85, 0.1);
    border-left: 1px solid rgba(24, 48, 85, 0.1);
}

.first-feature-list-li {
    border-radius: 10px 0 0 10px;
}
.center-feature-list-li {
    border-radius: 0;
}

.third-feature-list-li {
    border-radius: 0 10px 10px 0;
}

.feature-list li div div {
    height: 50px;
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: #e6f1fe;
    display: flex;
    align-items: center;
    border-radius: 25px;
}

.feature-img {
}

.feature-list li div h3 {
    text-align: center;
    color: black;
    font-weight: bolder;
    font-size: 18px;
}

.feature-list li div h4 {
    text-align: center;
    color: #999999;
    font-size: 16px;
    font-weight: 300;
}

/* Pricing */

.pricing-container {
    margin-top: 60px;
}

.card-header {
    background-color: white;
    border: none;
}

.card-header h4 {
    padding-bottom: 0;
    padding-top: 20px;
    font-weight: bolder;
}

.list-unstyled,
.card-body {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.list-unstyled li {
    font-weight: 300;
    color: #5c6975;
}

.list-unstyled li > b {
    color: #5c6975;
}

.card-price {
    padding-top: 10px;
    font-size: 30px;
}

.revenue-share {
    font-size: 12px;
    color: #999999;
}

.dollar-sign {
    vertical-align: super;
    font-size: 17px;
}

.per-month {
    vertical-align: baseline;
    font-size: 17px;
}

.card {
    border: none;
    box-shadow: 0px 10px 20px rgba(24, 48, 85, 0.5) !important;
}

.card-body {
    border-bottom: 1px solid #e5e9ee;
    min-height: 180px;
    max-height: 180px;
}

.card-middle {
    padding-top: 20px !important;
    min-height: 0px !important;
    max-height: 90px;
}

.card-footer {
    border: none;
    background-color: white;
    padding-top: 20px;
}

.team-description {
    font-weight: 400 !important;
}

/* Bottom Section */

.blockchain-row {
    padding-top: 100px;
}

.blockchain-container {
    margin-top: 100px;
    margin-bottom: 50px;
    height: auto;

    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.blockchain-element {
    display: inline-block;
    margin-left: 50px;
    padding-bottom: 10px;
}
.blockchain-element:first-child {
    margin-left: 70px;
    display: inline-block;
    margin-left: 50px;
}

.blockchainTitle {
    font-weight: 700;
    font-size: 52px;
    margin-left: 50px;
}

.sliderImage {
    height: 350px;
    padding: 95px 95px 95px 95px;
}

.sliderTitle {
    font-weight: 600;
    font-size: 26px;
}

.sliderBody {
    font-weight: 300;
    font-size: 18px;
    white-space: normal;
}
/* Legal Section */

.indent {
    padding-left: 50px;
}

.dark-logo-fixed {
    position: fixed;
    top: 40px;
    left: 35px;
    height: 150px;
}

.grey-col {
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #f6f9fc;
    min-width: 250px;
}

.legal-container h1 {
    font-size: 72px;
}

.legal-container h6 {
    color: #555555;
    font-size: 16px;
    margin: 40px 0px;
}

.legal-container hr {
    margin-bottom: 30px;
}

.legal-container h2 {
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 20px;
}

.legal-container p {
    color: black;
    font-size: 16px;
}
/* Footer Section */

.black-footer-legal-text {
    color: #ff0000;
}
.black-footer-legal-text:hover {
    color: #a70000;
}

.footerTitle {
    font-weight: 600;
    font-size: 26px;
}

.footerText {
    color: white !important;
}

.whiteline {
    color: white;
    background-color: white;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contactButton {
    background-color: Transparent;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px;
    margin-left: 14px;
    cursor: pointer;
    font-size: 15px;
}

.contactButton:hover {
    background-color: white;
    color: black;
    border: 2px solid white;
}

.footerList {
    list-style-type: none;
    padding-left: 0px;
}

.footerList li {
    margin-bottom: 5px;
}

ul.footerList li a {
    color: white !important;
}

ul.footerList li a:hover {
    color: white !important;
}

.top-footer-row {
    padding-top: 50px;
    padding-left: 50px;
    margin-bottom: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.bottom-footer-row {
    padding-bottom: 50px;
    margin-left: 50px;
    margin-right: auto;
}

.footer-logo {
    margin-bottom: 50px;
}

/* Not found Page */

.not-found-container {
    min-height: 73vh;
    background-color: #F2F1F1;
}

.error-animation {
    height: 30vh;
    margin-top: 7vh;
    margin-bottom: 3vh;
}

.notfound-title {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 350%;
    color: #1f2638;
}

.notfound-sub {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 100%;
    margin-top: 20px;
    color: #1f2638;
}

.notfound-btn {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 5vh;
    font-size: 80%;
    max-width: 200px;
}

/* Blockchain Page */

.blockchain-page-container {
    padding-top: 100px;
    max-width: none;
}

.blockchain-page-contents {
    padding-top: 50px;
}
.blockchain-page-contents h4 {
    color: #72c8f9;
    font-size: 21px;
    text-transform: uppercase;
    padding-bottom: 15px;
}

.first-blockchain-page-row {
    padding-top: 85px;
}

.square:before {
    background-color: #f6f9fc;
    content: "";
    display: block;
    padding-top: 100%;
    margin-left: -15px;
    padding-bottom: 15px;
    margin-right: -15px;
}

.square {
    padding-bottom: 15px;
    margin-bottom: -15px;
}

.blockchain-page-img {
    position: absolute;
    height: auto;
    width: 50%;
    top: 25%;
    left: 25%;
}

.blockchain-page-title {
    font-size: 36px;
    font-weight: bold;
}

.blockchain-page-text {
    font-size: 19px;
    font-weight: normal;
}

.blockchain-page-text-container {
    padding: 50px 50px;
}

.blockchain-page-text-container h3 {
    padding-bottom: 20px;
}

.spaced {
    margin-left: 15px;
    float: right;
}

.spaced:first-child {
    margin-right: 35px;
}

.legal-footer-text {
    margin-right: 50px;
}

.footerCol {
    min-width: 250px;
}

/* Invest Page */

.line-after-nav {
    display: block;
    border-bottom: 1px solid rgba(7, 96, 115, 0.47);
    width: 95%;
    margin-left: 2.5%;
}

.invest-title-row {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 75px;
}

.invest-form-container {
    margin-bottom: 100px;
}

.invest-container {
    background-image: linear-gradient(to bottom right, #00b4ff, #f6f9fc);
}

/* Play Page */

.dark-title {
    font-weight: 900;
    font-size: 72px;
    color: white;
}

.dark-sub-title {
    color: #b1bac4;
    font-size: 36px;
    margin-bottom: 60px;
}

.btn-red {
    background-color: #e80003;
    color: white;
    transition: all 0.5s ease;
}

.btn-red:hover {
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    color: white;
    transform: translate(0, -3px);
}

.btn-blue {
    background-color: #3884ff;
    color: white;
    transition: all 0.5s ease;
}

.btn-blue:hover {
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    color: white;
    transform: translate(0, -3px);
}

.btn-outline-blue {
    border-color: #3884ff;
    color: #3884ff;
    transition: all 0.5s ease;
}

.btn-outline-blue:hover {
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    color: #3884ff;
    transform: translate(0, -3px);
}

.btn-outline-red {
    border-color: #e80003;
    color: #e80003;
    transition: all 0.5s ease;
}

.btn-outline-red:hover {
    box-shadow: 0px 5px 5px rgba(30, 35, 53, 0.15);
    color: #e80003;
    transform: translate(0, -3px);
}

.beta-coming-col {
    margin-top: 125px;
}

.player-form-container {
    width: 550px;
    height: 283px;
    background-color: white;
    border-radius: 5px;
    margin-top: 50px;
    box-shadow: 1px 1px 25px 1px #ff0000;
}

.player-form-title {
    color: #e8830c;
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
}

.btn-player-form {
    display: block;
    float: right;
    top: -125px;
    position: relative;
    left: 320px;
    width: 170px;
    border-radius: 2px;
    background-color: #ff0000;
    border-color: #ff0000;
    margin-top: 145px;
}

.btn-player-form:hover {
    background-color: #e80c0c;
    border-color: #e80c0c;
}

.player-row {
    margin-top: -88px;
}

.player-images {
    width: auto;
    height: 110px;
}

.gamer-images-row {
    margin-top: 300px;
}

.player-image-header {
    color: white;
    font-size: 21px;
    font-weight: bold;
    margin-top: 30px;
}

.player-image-paragraph {
    color: #f8dacf;
    font-size: 18px;
    font-weight: 300;
    max-width: 300px;
}

.bottom-player-image {
    position: absolute;
    right: -40px;
    width: 90%;
    margin-top: 60px;
}

.player-images-col {
    min-width: 500px;
}

.player-top-form-label {
    margin-top: 30px;
    font-size: 16px;
    color: #1e2335;
    margin-left: 40px;
    text-align: left;
}

/* FAQ */

.still-have-questions {
    color: #555555;
    font-size: 24px;
    font-weight: 300;
    padding: 50px 30px;
}

.iconIsHidden {
    display: none;
}
.faq-question {
    cursor: pointer;
}

.faq-answer {
    font-size: 18px;
    font-weight: normal;
    padding-left: 40px;
}

.answer-container {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.faq-container {
    margin-top: 60px;
}

.faq-row::after {
    content: " ";
    border: 1px solid #d9d9d9;
    margin-top: 40px;
    width: 100%;
    min-width: 720px;
}

.active-collapsible {
    max-height: 1000px;
    margin-top: 40px;
}
/* Developer Page */

.dark-logo {
    position: absolute;
    top: 40px;
    left: 35px;
    height: 35px;
}

.sub-title {
    color: #555555;
    font-size: 25px;
}

.title-row {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
}

.form-container {
    width: 550px;
    height: 397px;
    padding-top: 20px;
    background-color: white;
    border-radius: 5px;
    margin-top: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 118, 255, 0.3),
    0 6px 20px 0 rgba(0, 118, 255, 0.29);
}

.svg-back-container {
    position: absolute;
    width: 100%;
    left: 0px;
    z-index: -1002;
    overflow: hidden;
}
.svg-red-back-container {
    position: absolute;
    width: 100%;
    left: 0px;
    z-index: -1002;
    overflow: hidden;
}
.svg-red {
    width: 101%;
    min-width: 1470px;
}

.svg-back {
    width: 101%;
    min-width: 1470px;
}

.success-animation {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.ml-success-title {
    margin-top: 110px !important;
}
.ml-success-title-red {
    margin-top: 20px !important;
}

.form-title {
    color: #000000;
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
}

.form-label {
    margin-top: 30px;
    font-size: 16px;
    color: #1e2335;
    margin-left: 20px;
}

.form-input::-webkit-input-placeholder,
.top-form-input::-webkit-input-placeholder {
    /* Edge */
    color: #bbbbbb;
    font-size: 14px;
    font-weight: 300;
    position: relative;
}

.form-input:-ms-input-placeholder,
.top-form-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbbbbb;
    font-size: 14px;
    font-weight: 300;
    position: relative;
}

.form-input::placeholder,
.top-form-input::placeholder {
    color: #bbbbbb;
    font-size: 14px;
    font-weight: 300;
    position: relative;
}

.top-form-input {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    height: 36px;
    background-color: #f6f9fc;
    margin-top: 41px;
    margin-left: -95px;
    margin-right: 60px;
    padding-left: 15px !important;
    padding-bottom: 2px !important;
}

.top-form-label {
    margin-top: 50px;
    font-size: 16px;
    color: #1e2335;
    margin-left: 40px;
    margin-left: 20px;
}

.form-input {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    height: 36px;
    background-color: #f6f9fc;
    margin-top: 21px;
    margin-left: -95px;
    margin-right: 60px;
    padding-left: 15px !important;
    padding-bottom: 2px !important;
}

.btn-developer-form {
    display: block;
    float: right;
    position: relative;
    width: 170px;
    border-radius: 2px;
    left: 300px;
    top: 20px;
}

.list-header {
    color: white;
    font-size: 36px;
}

.coming-soon-col-dev {
    margin-top: 140px;
    margin-left: 115px;
}

.coming-soon-list {
    margin-left: 0px;
}

.coming-soon-list li {
    color: white;
    font-size: 18px;
    max-width: 309px;
    margin: 10px 0px;
}

.images-row {
    margin-top: 65px;
    margin-bottom: 65px;
}

.developer-images {
    height: 100px;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

.developer-image-header {
    color: white;
    font-size: 21px;
    font-weight: bold;
}

.developer-image-paragraph {
    color: white;
}

.white-footer-row {
    background-color: white;
    height: 75px;
}

.white-footer-icon-container {
    padding: 20px 40px;
}

.white-footer-icon-container a {
    padding-right: 15px;
}

.white-footer-legal-container {
    padding: 20px 40px;
    text-align: right;
}

.white-footer-legal-container span {
    color: #a3ddff;
}

.white-footer-legal-text {
    padding-right: 20px;
}

.black-footer-legal-text {
    padding-right: 20px;
}

/*Pay Pal Page*/

.payment-title h1 {
    text-align: center;
    margin: 20% 0 10% 0;
    font-size: 18px;
    padding: 18px;
    line-height: 25px;
}

.svg-container-dev {
    height: 639px;
}

.app-store-image-container {
    margin-top: 120px;
}

.app-store-image-1 {
    margin-right: 30px;
}

.payment-results-page-container {
    margin: 30px 30px;
}

.payment-results-page-container p {
    font-size: 24px;
}

.payment-results-page-container button {
    font-size: 24px;
    color: white;
    background-color: #2ab27b;
    border-radius: 10px;
    border: none;
    width: 100%;
    height: 76px;
    margin-bottom: 25px;
}
@media only screen and (max-width: 1522px) {
    .hello-svg-back {
        height: 750px;
    }
}
/*Media Queries for different sized screens*/
@media only screen and (max-width: 1354px) {
    .right-img-container-main {
        max-width: 45%;
    }
}

@media only screen and (max-width: 1280px) {
    .hello-svg-back {
        height: 700px;
    }
}

@media only screen and (max-width: 1250px) {
    .grey-col {
        background-color: white;
    }

    .main-sub {
        width: 500px;
    }
}

@media only screen and (max-width: 1201px) {
    .top-white-bar {
        right: -100px;
        left: auto;
    }
    .bottom-white-bar {
        right: -70px;
        bottom: 0;
        left: auto;
    }
    .app-caption-text {
        padding-right: -20px;
        margin-left: -87px;
    }

    .iMessage-extension-text {
        margin-left: -52px;
    }

    .phones-main-caption-text {
        transform: translate(-115px, 0px);
    }

    .caption-container {
        width: 410px;
    }

    .beta-container {
        right: -340px;
        left: inherit;
    }

    .iMessage-image {
        transform: translate(10%, -435px);
    }
    .dark-logo-fixed {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 10px;
    }

    .right-img-container-main {
        position: absolute;
        padding-left: 35%;
        margin-top: 440px;
    }

    .thick {
        margin-top: 95px;
    }

    .thick-header {
        width: 570px;
        margin-top: 340px;
    }

    .main-sub {
        width: 570px;
    }

    .grey-col {
        background-color: white;
    }

    .app-store-image-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: -128px;
    }

    .comingsoonButton,
    .comingsoonButtonPlay {
        left: 5%;
        width: 90%;
        position: absolute;
        margin-top: -100px;
    }

    .develop-call-to-action-container {
        margin-top: 150px;
        flex: 10 0 25%;
        max-width: none;
    }

    .play-call-to-action-container {
        margin-top: 150px;
        flex: 10 0 25%;
        max-width: none;
    }

    .thin {
        max-width: none;
    }

    .focusImage {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .focusDiv {
        position: relative;
    }

    .focusDiv h1 {
        padding-top: 15px;
    }

    .midde-section-container {
        padding-top: 50px;
    }

    .focus-row {
        padding-top: 50px;
    }

    .focusTitle,
    .focusBody,
    .focusLink {
        padding-left: 22%;
        padding-right: 22%;
    }

    .focusLine {
        width: 66%;
        margin-left: 22%;
    }

    .downloads-container {
        padding-top: 75px;
    }

    .catalog-container-1 {
        margin-top: 50px;
    }
    .comingsoonButtonFooter {
        margin-bottom: 50px;
    }
    .main-text-and-buttons {
        margin-top: -300px;
        z-index: -10;
    }
    .develop-call-to-action-container {
        margin-top: 60px;
    }
    .top-section-main-container {
        margin-bottom: 690px;
    }
}

@media only screen and (max-width: 1165px) {
    .top-section-product-list-container {
        left: -20px;
    }
    .cta-container {
        margin-left: 30px;
    }
    .cta-container h1 {
        font-size: 40px;
    }
    .cta-container h2 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1065px) {
    .submit-text {
        bottom: 111px;
    }
    .arrow-right-input-icon {
        bottom: 127px;
    }
}

@media only screen and (max-width: 991px) {
    .submit-text {
        bottom: 48px;
        width: 140px;
    }

    .arrow-right-input-icon {
        bottom: 57px;
    }
}

@media only screen and (max-width: 960px) {
    .submit-text {
        bottom: 48px;
        width: 140px;
    }

    .arrow-right-input-icon {
        bottom: 57px;
    }

    .launch-boost-row {
        padding-top: 50px;
    }
    .launch-text-ctn {
        padding-left: 50px;
    }

    .boost-text-ctn {
        padding-right: 50px;
    }

    .hello-title-col {
        text-align: center;
        max-width: none;
        padding-left: 10px;
        padding-right: 10px;
    }
    .hello-sketch-col {
        padding: 0;
    }
    .handshake-items-container {
        height: 100px;
        padding-left: 0;
        margin-left: auto;
        margin-right: auto;
        display: table;
    }
    .feature-list {
        margin-top: 0px;
    }
    .feature-list li {
        float: none;
        width: 100%;
        padding-top: 10px;
    }
    .feature-list li div {
        margin-top: 0px;
    }
    .center-feature-list-li {
        border-top: 1px solid rgba(24, 48, 85, 0.1);
        border-bottom: 1px solid rgba(24, 48, 85, 0.1);
    }
    .first-feature-list-li {
        border-radius: 10px 10px 0 0;
    }

    .third-feature-list-li {
        border-radius: 0 0 10px 10px;
    }
    .hello-svg-back {
        height: 1000px;
    }


}

@media only screen and (max-width: 991px) {
    .phone-video-container {
        height: 530px;
    }
    .phone-video {
        top: 55%;
        width: 200px;
    }

    .animated-gif-welcome {
        width: 200px;
        height: 398px;
    }

    .top-section-cta-container {
        height: 500px;
        top: -60px;
        transform: translateY(0);
    }

    .video-label-container {
        top: 29%;
        transform: translateY(-71%);
    }

    .pricing-main {
        padding-top: 20px;
    }

    .card {
        box-shadow: 0px 10px 20px rgba(24, 48, 85, 0.5) !important;
    }

    .thick {
        font-size: 46px;
    }

    .faq-row::after {
        min-width: 0px;
    }

    .navbar-brand {
        margin-top: 3px;
    }

    .semi {
        font-size: 36px;
    }

    .app-store-image-container {
        margin-top: -87px;
    }

    .title {
        font-size: 40px;
    }

    .sub-title {
        font-size: 25px;
    }

    .dark-title {
        font-size: 40px;
    }

    .dark-sub-title {
        font-size: 25px;
    }

    .legal-container h1 {
        font-size: 40px;
    }

    .coming-soon-col {
        margin-top: 140px;
        margin-left: 115px;
    }

    .coming-soon-col-dev {
        margin-top: 50px;
        margin-left: 170px;
    }

    .pricing-container {
        max-width: 960px;
    }

    .card-deck .card {
        min-width: 220px;
    }

    .border-top {
        border-top: 1px solid #e5e5e5;
    }
    .border-bottom {
        border-bottom: 1px solid #e5e5e5;
    }

    .box-shadow {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
    }

    .bottom-player-image {
        position: static;
    }

    .gamer-images-row {
        margin: 0px;
    }

    .player-img-col {
        display: none;
    }

    .player-image {
        min-width: 300px;
        margin-top: 100px;
    }

    .beta-coming-col {
        margin-top: 50px;
    }

    .play-call-to-action-container {
        margin-left: 8.333333%;
        flex: inherit;
        max-width: inherit;
        margin-top: 48px;
    }

    .develop-call-to-action-container {
        flex: inherit;
        max-width: inherit;
        margin-top: 70px;
    }

    .navigation-bar {
        height: 60px;
    }

    .focusTitle,
    .focusBody,
    .focusLink {
        padding-left: 13%;
        padding-right: 13%;
    }

    .focusLine {
        width: 74%;
        margin-left: 13%;
    }

    .catalog-container-1 {
        position: absolute;
        left: 0px;
    }

    .catalog-container-2 {
        position: absolute;
        left: 0px;
        margin-top: 215px;
    }

    .downloads-container {
        padding-bottom: 430px;
    }

    .rowImage {
        width: 100px;
    }

    .blockchain-row {
        padding-top: 50px;
    }

    .blockchain-container {
        margin-top: 50px;
    }

    .square {
        width: 185px;
        height: 185px;
        border-radius: 92.5px;
        margin-left: auto;
        margin-right: auto;
    }

    .square:before {
        border-radius: 50%;
        margin-left: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
    }

    .blockchain-page-img {
        top: 20%;
    }

    .first-blockchain-page-row {
        padding-top: 25px;
    }

    .blockchain-page-text-container {
        padding: 15px;
    }

    .spaced {
        float: left;
    }

    .spaced:first-child {
        margin-right: inherit;
        margin-left: 0;
        margin-bottom: 25px;
    }

    .legal-footer-text {
        margin-right: inherit;
        min-width: 400px;
        margin-left: 25px;
    }

    .svg-back {
        min-width: 1880px;
        margin-left: -240px;
    }

    .svg-red {
        min-width: 2180px;
    }

    .invest-title-row {
        max-width: 650px;
        margin-top: 50px;
    }
    .form-container-dev {
        height: 400px;
    }

    .svg-container-dev {
        height: 1120px;
    }
}

@media only screen and (max-width: 818px) {
    .faq-li {
        width: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .hello-read-docs {
        display: none;
    }

    .top-section-logo {
        left: 30px;
        top: 90px;
    }

    .faq-button-light {
        right: 30px;
        top: 35px;
    }

    .player-images-col {
        min-width: 0px;
    }

    .main-sub {
        margin-left: 8.33333%;
        width: 80%;
    }

    .comingsoonButton {
        margin-top: -110px;
    }

    .faq-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .faq-container {
        margin-top: 0px;
    }

    .develop-call-to-action-container,
    .thick {
        margin-left: 8.33333%;
    }

    .focusTitle,
    .focusBody,
    .focusLink {
        padding-left: 0px;
        padding-right: 0px;
    }

    .focusLine {
        width: 100%;
        margin-left: 0px;
    }

    .catalog-container-2 {
        margin-top: 50px;
        margin-left: 50%;
        width: 50%;
    }

    .catalog-container-1 {
        margin-top: 50px;
        margin-left: 0px;
        width: 50%;
    }

    .rowImage {
        width: 120px;
    }

    .downloads-container {
        padding-bottom: 750px;
    }

    .focusImage {
        width: 90%;
    }

    .focusDiv {
        width: 90%;
        height: 110px;
    }

    .gameTitle {
        font-size: 20px;
    }

    .gameBody {
        font-size: 15px;
    }

    .blockchainTitle {
        font-size: 36px;
    }

    .blockchain-page-contents {
        padding-top: 20px;
    }

    .legal-footer-text {
        margin-right: inherit;
        min-width: 550px;
        margin-left: -27px;
    }

    .white-footer-legal-container {
        text-align: left;
    }

    .svg-back {
        min-width: 2510px;
        margin-left: -740px;
    }

    .ml-success-title {
        margin-top: 80px !important;
    }

    .coming-soon-col-dev {
        margin-left: 10px;
        margin-top: 70px !important;
    }

    .btn-developer-form {
        left: 30%;
    }
    .svg-container-dev {
        height: 1210px;
    }
    .submit-game-heading {
        font-size: 40px;
    }
}
@media only screen and (max-width: 640px) {
    .launch-text-ctn,
    .boost-text-ctn {
        padding: 0 50px;
    }
}
@media only screen and (max-width: 627px) {
    .animated-gif-hello {
        min-width: 300px;
    }

    .bottom-white-bar {
        bottom: -24.5%;
    }

    .svg-container-dev {
        height: 1490px;
        margin-top: 530px;
    }

    .legal-footer-text {
        min-width: none;
        margin-left: 0;
    }

    .app-store-image-1,
    .app-store-image-2 {
        height: 40px;
    }

    .thick-header {
        line-height: 60px;
        margin-top: 260px;
    }

    .right-img-container-main {
        margin-top: 400px;
    }

    .right-img-container-main {
        padding-left: 30%;
    }

    .main-title-row {
        margin-top: 80px;
    }

    .comingsoonButton {
        margin-top: -170px;
        padding-top: 70px;
    }

    .still-screen {
        margin-left: 110px;
    }

    .beta-container {
        right: -270px;
    }

    .app-caption-text {
        margin-left: -80px;
    }

    .caption-container {
        width: 330px;
    }

    .iMessage-extension-text {
        margin-left: -99px;
    }

    .phones-main-caption-text {
        width: 430px;
    }
}

@media only screen and (max-width: 577px) {
    .faq-question-text {
        font-size: 25px;
        line-height: 25px;
    }
    .faq-answer {
        font-size: 20px;
        font-weight: normal;
        padding-left: 10px;
    }
    .form-container {
        width: 95%;
        margin-left: 2.5%;
    }

    .form-container-dev {
        height: 396px;
    }

    .player-form-title {
        display: none;
    }

    .player-form-container {
        width: 95%;
        height: 200px;
        margin-left: 2.5%;
    }

    .form-label,
    .top-form-label {
        margin-bottom: 0px;
        margin-left: 15px;
        color: #326992;
        text-transform: uppercase;
        margin-top: 10px;
    }

    .player-top-form-label {
        margin-bottom: 0px;
        margin-left: 15px;
        color: #c20000;
        text-transform: uppercase;
        margin-top: 10px;
    }

    .top-form-input,
    .form-input {
        margin-top: 0px;
        background: white;
        border: none;
        margin-left: 5px;
        margin-right: 2.5%;
        margin-bottom: 10px;
    }

    .mobile-only-line {
        width: 100%;
        border-bottom: 1px solid black;
        border-color: rgba(7, 96, 115, 0.07);
    }

    .btn-player-form {
        left: 45%;
    }

    .app-store-image-container {
        margin-top: -150px;
    }

    .thick {
        max-width: 410px;
        font-size: 40px;
    }

    .main-title-row {
        width: 100%;
    }

    .main-image {
        height: 300px;
    }

    .navbar-brand {
        padding-left: 0px;
    }

    .focusDiv {
        height: 140px;
    }

    .sliderImage {
        height: 195px;
        padding: 50px;
    }

    .blockchain-element {
        width: 205px;
        margin-left: 30px;
    }

    .top-footer-row {
        padding-left: 10px;
    }

    .bottom-footer-row {
        padding-left: 10px;
        margin-left: 0;
    }

    .svg-red {
        min-width: 2000px;
    }

    .btn-developer-form {
        left: 45%;
    }
}

@media only screen and (max-width: 478px) {
    .submit-text {
        display: none;
    }
    .arrow-right-input-icon {
        bottom: 87px;
    }
}

@media only screen and (max-width: 469px) {
    .animated-gif {
        width: 50%;
        height: 100%;
        padding: 0 10px;
        border-radius: 60px;
    }

    .phone-video img {
        border-radius: 40px !important;
    }

    .video-label-container {
        width: 100%;
    }

    .cta-container h1 {
        font-size: 40px;
    }
    .cta-container h2 {
        font-size: 25px;
    }
    .cta-container p {
        font-size: 18px;
        padding-right: 20px;
    }

    .top-white-bar {
        right: auto;
        left: 0;
        top: 160px;
        background-color: white;
        width: 120%;
    }
    .bottom-white-bar {
        bottom: -131px;
    }

    .main-button-primary {
        margin-right: 50px;
    }
    .main-button-secondary {
        margin-top: -10px;
        margin-left: 0;
    }

    .develop-call-to-action-container {
        min-width: 0 !important;
    }
    .thick {
        width: 60%;
    }
    .main-sub {
        width: 60%;
    }

    .still-screen {
        margin-left: 100px;
    }
    .beta-container {
        right: -210px;
        top: 140px;
        height: 34px;
        width: 115px;
    }
    .beta-container p {
        padding-top: 5px !important;
    }
    .caption-container {
        width: 260px;
    }
    .phones-main-caption-text {
        width: 340px;
        margin-left: 70%;
    }
    .app-caption-text {
        margin-left: -50px;
    }
    .iMessage-extension-text {
        margin-left: -139px;
    }

    .arrow-right-input-icon {
        position: absolute;
        right: 7%;
        bottom: 47px;
        width: 43px;
    }

    .submit-text {
        display: none;
    }

    .top-submit-game-ctn {
        font-size: 14.2px;
    }
}

@media only screen and (max-width: 400px) {
    .hello-svg-back {
        height: 1000px;
        top: 100px;
    }
    .thick,
    .main-sub,
    .develop-call-to-action-container {
        margin-left: 20px;
    }


}

@media only screen and (max-width: 348px) {
    .arrow-right-input-icon {
        bottom: 70px;
    }
}

@media only screen and (max-width: 340px) {
    .bottom-white-bar {
        bottom: -81px;
    }

    .focusDiv {
        height: 150px;
    }

    .thick-header,
    .main-sub {
        margin-left: 10px;
        width: 50%;
    }

    .comingsoonButton {
        margin-top: -300px;
    }

    .main-button-primary {
        margin-left: -20px;
    }

    .main-image {
        margin-left: 5px;
    }

    .dark-logo {
        position: absolute;
        top: 20px;
        left: 15px;
        height: 25px;
    }

    .title-row {
        margin-top: -30px;
    }

    .title {
        font-size: 30px;
    }

    .dark-title {
        font-size: 30px;
    }

    .sub-title {
        font-size: 20px;
    }

    .dark-sub-title {
        font-size: 20px;
    }

    .submitButton {
        left: 35%;
    }

    .svg-back {
        min-width: 2640px;
    }

    .investBar {
        height: 60px;
    }

    .invest-title-row {
        margin-top: 40px;
    }

    .focusLink {
        font-size: 90%;
    }
    .btn-developer-form {
        left: 21%;
    }
    .svg-back-container {
        height: 1490px;
    }
    .list-header {
        text-align: center;
    }

    .thick {
        font-size: 35px;
        line-height: 48px;
    }
    .thick,
    .main-sub {
        margin-left: 20px;
    }
    .main-button-primary {
        margin-left: 5px;
    }

    .still-screen {
        margin-left: 90px;
        height: 250px;
    }

    .app-caption-text {
        margin-left: -36px;
        padding-right: 120px;
    }

    .iMessage-extension-text {
        margin-left: -40px;
    }

    .beta-container {
        right: -180px;
    }
    .caption-container {
        width: 210px;
    }
    .phones-main-caption-text {
        width: 290px;
        margin-left: 150%;
    }
}
